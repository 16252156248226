<template>
    <!-- 基本信息 -->
    <div class="baseinfo">
        <div class="row mb-4">
            <div class="col-3">
                <div>
                    <label class="form-label">{{ $t('attribute.type') }}</label>
                    <input type="text" class="form-control" v-model="baseInfo.type" />
                </div>
            </div>
            <!-- <div class="col-3">
				<div>
					<label class="form-label">{{ $t('fiber.diameter') }}</label>
					<div class="input-group">
						<input type="text" class="form-control" v-model="baseInfo.diameter" />
						<span class="input-group-text">{{ $t('fiber.diameter_unit') }}</span>
					</div>
				</div>
			</div>
			<div class="col-3">
				<div>
					<label class="form-label">{{ $t('fiber.density') }}</label>
					<div class="input-group">
						<input type="text" class="form-control" v-model="baseInfo.density" />
						<span class="input-group-text">{{ $t('fiber.density_unit') }}</span>
					</div>
				</div>
			</div>
			<div class="col-3">
				<div>
					<label class="form-label">{{ $t('fiber.water_contact_angle') }}</label>
					<div class="input-group">
						<input type="text" class="form-control" v-model="baseInfo.water_contact_angle" />
						<span class="input-group-text">{{ $t('fiber.water_contact_angle_unit') }}</span>
					</div>
				</div>
			</div>
			<div class="col-3  py-3">
				<div>
					<label class="form-label">{{ $t('fiber.thermal_conductivity') }}</label>
					<div class="input-group">
						<input type="text" class="form-control" v-model="baseInfo.thermal_conductivity" />
						<span class="input-group-text">{{ $t('fiber.thermal_conductivity_unit') }}</span>
					</div>
				</div>
			</div>
			<div class="col-3 py-3">
				<div>
					<label class="form-label">{{ $t('fiber.water_diffusion_coefficient') }}</label>
					<div class="input-group">
						<input type="text" class="form-control" v-model="baseInfo.water_diffusion_coefficient" />
						<span class="input-group-text">{{ $t('fiber.water_diffusion_coefficient_unit') }}</span>
					</div>
				</div>
			</div> -->
        </div>
        <!-- <div class="row mb-4 justify-content-between"></div> -->
        <div class="row">
            <div class="col-3">
                <div>
                    <label class="form-label">{{ $t('attribute.code') }}</label>
                    <Multiselect id="item" class="form-control" v-model="baseInfo.code_1" label="title"
                        value-prop="code" :close-on-select="true" :searchable="true" :create-option="false"
                        :options="code_options" @select="selectCode" @clear="clearCode" />
                </div>
            </div>
            <div class="col-3">
                <div style="position: relative; top: 100%; transform: translateY(-100%);">
                    <Multiselect
                        id="item"
                        class="form-control"
                        v-model="hscode"
                        label="title"
                        value-prop="code"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="sub_list"
                        @select="getCodeTitle"
                        @clear="clearCodeTitle"
                    />
                </div>
            </div>
            <div class="col-3">
                <div style="position: relative; top: 100%; transform: translateY(-100%);"><input
                        v-model="baseInfo.code_2" @input="baseInfo.code_2 = stringRegEx(0,baseInfo.code_2,2)"
                        type="text" class="form-control" /></div>
            </div>
            <div class="col-3">
                <div style="position: relative; top: 100%; transform: translateY(-100%);"><input
                        v-model="baseInfo.code_ciq" @input="baseInfo.code_ciq = stringRegEx(1,baseInfo.code_ciq,3)"
                        type="text" class="form-control" /></div>
            </div>
        </div>
    </div>
    
    <UploadFileView v-model:selectedList="pictures_preview" :title="$t('attribute.picture')" :hint="$t('base.upload_picture_limit')"></UploadFileView>
    
</template>

<script>
    import UploadFileView from '@/views/components/UploadFileView.vue'
    import _ from 'lodash';
    import {
        getHSCode
    } from '@/service';
    import {
        getFileValuesFromDetail
    } from '@/utils/fetch-data';
    import {
        stringRegEx
    } from '@/utils/common.js'
    import { getHscodeDbIndex } from "@/service/index";

    export default {
        components: {
            UploadFileView
        },
        props: {
            addForm: {
                type: Object,
                default: () => {}
            },
            detailForm: {
                type: Object,
                default: () => {}
            },
            search_list: {
                type: Object,
                default: () => {}
            }
        },
        emits: ['update:addForm', 'update:hscode', 'update:hscodeTitle', 'update:associatedIndex'],
        data() {
            return {
                baseInfo: {
                    code_1: '',
                    code_2: '',
                    code_ciq: '',
                    type: '',
                    diameter: '',
                    density: '',
                    water_contact_angle: '',
                    thermal_conductivity: '',
                    water_diffusion_coefficient: '',
                    code: '',
                    moisture_regain: [], // [{"rh": "13", "regain": "32"}]
                    brand_name: '',
                    supplier_name: '',
                    price: '',
                    inventory: '',
                    pictures: []
                },
                code_options: [],
                sub_list: [],
                pictures_preview: [],
              hscode: null,
              hscodeTitle: null,
              associatedIndex:{}, // 关联的指标
              inited: false,
            };
        },
        methods: {
            stringRegEx,
            selectCode(init) {
                const self = this;
                const index = _.findIndex(this.code_options, function(o) {
                    return o.code == self.baseInfo.code_1;
                });
                if (init != 'init') {
                    this.baseInfo.code = '';
                }
                if (index >= 0 && index < this.code_options.length) {
                    const item = this.code_options[index];
                    this.sub_list = item.sub_list;
                  if (this.hscode && init == "init") {
                    // 获取codeTitle
                    const index = _.findIndex(this.sub_list, function (o) {
                      return o.code == self.hscode;
                    });
                    if (index >= 0 && index < this.sub_list.length) {
                      const item = this.sub_list[index];
                      this.hscodeTitle = item.title;
                      this.$emit('update:hscodeTitle', item.title)
                    }
                  }
                }
            },
            clearCode() {
                this.baseInfo.code = '';
                this.sub_list = [];
            },
          getCodeTitle(code) {
            console.log('code变化：', code)
            if (!code) {
              this.hscodeTitle = "";
              this.associatedIndex = {};
              return;
            }
            this.baseInfo.code = code;
            const index = _.findIndex(this.sub_list, function (o) {
              return o.code == code;
            });
            if (index >= 0 && index < this.sub_list.length) {
              const item = this.sub_list[index];
              this.hscodeTitle = item.title;
            }
            // 获取关联的指标
            const params = {
              code: code,
              key: 'fibre'
            };
            getHscodeDbIndex(params)
                .then((res) => {
                  if (res && res.payload) {
                    this.associatedIndex = res.payload
                    this.$emit('update:associatedIndex', this.associatedIndex)
                    this.$emit('update:hscodeTitle', this.hscodeTitle)
                    this.$emit('update:hscode', code)
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
          },
          clearCodeTitle() {
            this.hscodeTitle = "";
            this.associatedIndex = {};
          },

            requestHSCode(key) {
              if (this.code_options.length) {
                return
              }
                const params = {
                    key: key
                };
                getHSCode(params)
                    .then(res => {
                        if (res && res.payload) {
                            this.code_options = res.payload;
                            this.selectCode('init');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
        watch: {
            pictures_preview: {
                immediate: true,
                deep: true,
                handler(val) {
                    if (val) {
                        const list = _.map(this.pictures_preview, 'id')
                        this.baseInfo.pictures = list
                    }
                }
            },
            detailForm: {
                handler(val) {
                    if (val && !this.inited) {
                        this.inited = true;
                        const detailFormData = _.cloneDeep(val);
                        this.baseInfo.type = _.get(detailFormData, 'type', '');
                        this.baseInfo.diameter = _.get(detailFormData, 'diameter', '');
                        this.baseInfo.density = _.get(detailFormData, 'density', '');
                        this.baseInfo.water_contact_angle = _.get(detailFormData, 'water_contact_angle', '');
                        this.baseInfo.thermal_conductivity = _.get(detailFormData, 'thermal_conductivity', '');
                        this.baseInfo.water_diffusion_coefficient = _.get(detailFormData, 'water_diffusion_coefficient',
                            '');

                        this.baseInfo.brand_name = _.get(detailFormData, 'brand_name', '');
                        this.baseInfo.supplier_name = _.get(detailFormData, 'supplier_name', '');
                        this.baseInfo.price = _.get(detailFormData, 'price', '');
                        this.baseInfo.inventory = _.get(detailFormData, 'inventory', '');
                        this.baseInfo.moisture_regain = _.get(detailFormData, 'moisture_regain', []);


                      if (detailFormData.code && !this.hscode) {
                        this.baseInfo.code = _.get(detailFormData, "code", "");
                        this.hscode = this.baseInfo.code;
                        // console.log('hscode初始赋值：', this.hscode)

                        // 获取关联的指标
                        const params = {
                          code: this.hscode,
                          key: 'fibre'
                        };
                        getHscodeDbIndex(params)
                            .then((res) => {
                              if (res && res.payload) {
                                this.associatedIndex = res.payload
                                this.$emit('update:associatedIndex', this.associatedIndex)
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });

                        if (this.baseInfo.code && this.baseInfo.code.length == 6) {
                          this.baseInfo.code_1 = this.baseInfo.code.slice(0, 4);
                          this.selectCode("init");
                        }
                      }

                      this.baseInfo.code_2 = _.get(detailFormData, "code_2", "");
                      this.baseInfo.code_ciq = _.get(detailFormData, "code_ciq", "");


                      if (!this.baseInfo.pictures.length) {
                            const picture_data = getFileValuesFromDetail(detailFormData, 'pictures');
                            this.baseInfo.pictures = picture_data.ids;
                            this.pictures_preview = picture_data.previews;
                        }
                    }
                    this.requestHSCode('fibre');
                },
                deep: true,
                immediate: true
            },
            baseInfo: {
                handler(val) {
                    let re_form = _.cloneDeep(val);
                    this.$emit('update:addForm', re_form);
                },
                deep: true
            }
        }
    };
</script>

<style scoped lang="scss"></style>
