<template>
    <!-- <b-row class="pb-4">
        <b-col :cols="cols" v-for="(item, index) in list" :key="index">
            <div :class="[index >= 12/cols && 'mt-4']">
                <div style="font-weight: bold;">
                    <label class="form-label">
                        <span>{{ item.index_name }}</span>
                        <i :id="item.field_name" v-if="item.reference" class="ri-question-line"
                            @mouseover="mouseOver(item)" @mouseleave="mouseLeave(item)"></i>
                    </label>
                </div>
                <div v-if="item.unit && item.unit.length" class="input-group">
                    <input disabled type="text" class="form-control" v-model="item.value" />
                    <span class="input-group-text">{{ item.unit }}</span>
                </div>
                <input disabled v-else type="text" class="form-control" v-model="item.value" />
            </div>
            <b-popover :target="item.field_name" v-model:show="item.show" triggers="">
                <div>{{ item.reference }}</div>
            </b-popover>
        </b-col>
    </b-row> -->
	<table class="table table-bordered">
		<thead class="table-light">
			<tr>
				<th style="width: 25%;">{{ $t('attribute.name') }}</th>
				<th style="width: 25%;">{{ $t('attribute.value') }}</th>
				<th style="width: 25%;">{{ $t('attribute.unit') }}</th>
				<th style="width: 25%;">{{ $t('attribute.reference') }}</th>
			</tr>
		</thead>
		<tbody class="table-responsive align-middle">
			<tr v-for="(item, index) in list" :key="index">
				<td>{{ item.index_name }}</td>
				<td>{{ item.value }}</td>
				<td>{{ item.unit }}</td>
				<td>{{ item.reference }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
    import _ from 'lodash';
    export default {
        props: {
            detailForm: {
                type: Object,
                default: () => {}
            },
            flag: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                cols: 6,
                list: [],
            };
        },
        methods: {
            mouseOver(item) {
                item.show = true
            },
            mouseLeave(item) {
                item.show = false
            },
            updateUI() {
                if (!this.detailForm) {
                    return
                }
                const detailFormData = _.cloneDeep(this.detailForm);
                if (this.flag == 1) {
                    this.list = detailFormData.quality_index
                } else if (this.flag == 2) {
                    this.list = detailFormData.envrionmental_index
                } else {
                    this.list = detailFormData.business_index
                }
            }
        },
        watch: {
            detailForm: {
                handler(val) {
                    if (val) {
                        this.updateUI()
                    }
                },
                deep: true,
                immediate: true
            },
            flag: {
                handler() {
                    this.updateUI()
                },
                deep: true,
                immediate: true
            },
        },
    };
</script>

<style scoped lang="scss"></style>
<style>
    .bootstrap-select .dropdown-toggle:focus {
        outline: none !important;
    }
</style>
