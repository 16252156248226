<template>
    <!-- 图表 -->
    <div>
        <div :id="fabricChartId" style="width: 100%; height: 400px"></div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {
        sankeyChart,
        sankey1Chart
    } from '@/utils/chart.js';
    import {
        getSankeyChartInfo
    } from '@/service';

    export default {
        data() {
            return {
                baseInfo: [{
                        title: this.$t('attribute.type'),
                        key: 'type',
                        value: '',
                        unit: ''
                    },
                    {
                        title: this.$t('fiber.diameter'),
                        key: 'diameter',
                        value: '',
                        unit: this.$t('fiber.diameter_unit')
                    },
                    {
                        title: this.$t('fiber.density'),
                        key: 'density',
                        value: '',
                        unit: this.$t('fiber.density_unit')
                    },
                    {
                        title: this.$t('fiber.water_contact_angle'),
                        key: 'water_contact_angle',
                        value: '',
                        unit: this.$t('fiber.water_contact_angle_unit')
                    },
                    {
                        title: this.$t('fiber.thermal_conductivity'),
                        key: 'thermal_conductivity',
                        value: '',
                        unit: this.$t('fiber.thermal_conductivity_unit')
                    },
                    {
                        title: this.$t('fiber.water_diffusion_coefficient'),
                        key: 'water_diffusion_coefficient',
                        value: '',
                        unit: this.$t('fiber.water_diffusion_coefficient_unit')
                    },
                    {
                        title: this.$t('attribute.brand'),
                        key: 'brand_name',
                        value: '',
                        unit: ''
                    },
                    {
                        title: this.$t('attribute.supplier'),
                        key: 'supplier_name',
                        value: '',
                        unit: ''
                    },
                    {
                        title: this.$t('attribute.price'),
                        key: 'price',
                        value: '',
                        unit: this.$t('attribute.price_unit1')
                    },
                    {
                        title: this.$t('attribute.stock'),
                        key: 'inventory',
                        value: '',
                        unit: this.$t('attribute.stock_unit1')
                    },
                    {
                        title: this.$t('attribute.code'),
                        key: 'code',
                        value: '',
                        unit: ''
                    },
                ],
                moistureTitle: [this.$t('attribute.number'), this.$t('fiber.relative_humidity'), this.$t(
                    'fiber.moisture_regain1')],
                moistureList: [],
                pictures: [],
                showPreviewImg: false,
                src: '',
                fabricChartId: `fabric${+new Date()}`,
            };
        },
        props: {
            detailForm: {
                type: Object,
                default: () => {}
            }
        },
        watch: {
            detailForm: {
                handler(val) {
                    if (val) {
                        this.baseInfo.forEach(info => {
                            for (let v in val) {
                                if (info.key == v) {
                                    if (info.key == 'code') {
                                        const code = val[v];
                                        const code_2 = val['code_2'];
                                        const code_ciq = val['code_ciq'];
                                        let value = '';
                                        if (code) {
                                            value += code + '-';
                                        }
                                        if (code_2) {
                                            value += code_2 + '-';
                                        } else {
                                            value += '-';
                                        }
                                        if (code_ciq) {
                                            value += code_ciq;
                                        }
                                        info.value = value;
                                    } else {
                                        info.value = val[v];
                                    }
                                }
                            }
                        });
                        if (val['code_title']) {
                            this.baseInfo.push({
                                title: ' ',
                                key: 'code_title',
                                value: val['code_title'],
                                unit: ''
                            });
                        }
                        this.moistureList = _.get(val, 'moisture_regain', []);
                        this.pictures = _.get(val, 'pictures', []);
                        // this.requestSankeyChart();
                        console.log(this.detailForm)
                        if (this.detailForm.sankey) {
                            setTimeout(() => {
                                this.setChart(this.detailForm.sankey);
                            },0)
                            
                        }
                    }
                },
                deep: true,
                immediate: true
            },
            documentID: {
                handler(val) {
                    if (val) {
                        if (this.detailForm.sankey) {
                            this.setChart(this.detailForm.sankey);
                        }
                    }
                },
                deep: true,
                immediate: true,
            }
        },
        computed: {
            documentID() {
                return document.querySelector(`#${this.fabricChartId}`)
            }
        },
        methods: {
            requestSankeyChart() {
                const params = {
                    id: this.detailForm.id,
                    key: this.detailForm.key
                };
                getSankeyChartInfo(params)
                    .then(res => {
                        this.setChart(res.payload);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            setChart(data) {
                const chartDom = document.querySelector(`#${this.fabricChartId}`)
                if (!chartDom) {
                    return
                }
                let options = {
                    chartDom: chartDom,
                    data: data
                };
                console.log(options)
                sankey1Chart(options);
            },
            initChart() {
                let options = {
                    chartDom: document.querySelector(`#${this.fabricChartId}`)
                };
                sankeyChart(options);
            },
            baseItemTitle(item) {
                if (item.title == '') {
                    return item.title;
                }
                if (item.unit == '') {
                    return item.title;
                }
                return item.title + ' (' + item.unit + ')';
            },
            handleImgPreview(item) {
                this.src = item.url;
                if (this.src == undefined || this.src == null || this.src == '') {
                    return;
                }
                this.showPreviewImg = true;
            }
        }
    };
</script>

<style scoped lang="less"></style>
